import "./App.css";
import $ from "jquery";
import axios from "axios";
import React from "react";

axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
const baseURL = "https://my-worker.horselurrver8961.workers.dev";

function Post(key, username, title, contents) {
  return (
    <div key={key} className="card" style={{width: "60%"}}>
      <div className="card-header">
        @{username}
      </div>
      <div className="post">
        <p className="card-title">{title}</p>
        <p className="post-text">
          {contents}
        </p>
      </div>
    </div>
  );
}

function App() {
  const [posts, setPost] = React.useState(null);

  function createPost(e) {
    const contents = $("#contents").val();
    const username = $("#username").val();
    const title = $("#title").val();

    if (contents === "" || username === "" || title === "") {
      $("#alert").show("slow");
    } else {
      $("#alert").hide("slow");
      axios.post(baseURL + "/posts", {
          username: username,
          title: title,
          contents: contents,
        })
        .then((response) => {
          console.log(response.data);
          setPost(response.data);
      });
      $("#contents").val("");
      $("#username").val("");
      $("#title").val("");
    }
    e.preventDefault();
  }

  function TextArea() {
    return (
      <div style={{width: "60%"}}>
        <div id="alert" className="alert alert-warning" role="alert" style={{display: "none"}}>
          <strong>Oh no!</strong> One of your input fields is empty.
        </div>

        <div className="card">
          <div className="card-body">

            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">@</span>
              </div>
              <input type="text" id="username" className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1"/>
            </div>

            <div className="input-group mb-3">
              <input type="text" id="title" className="form-control" placeholder="Post title" aria-label="Post title"/>
            </div>

            <div className="form-group">
              <textarea className="form-control" id="contents" rows="3" placeholder="What are you thinking?"></textarea>
            </div>
            <br/>
            <div className="btn-group" style={{float: "left"}}>
              <button onClick={createPost} type="submit" className="btn btn-primary">Share</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  React.useEffect(() => {
    axios.get(baseURL).then((response) => {
      setPost(response.data);
    });
  }, []);

  if (!posts) return (
    <div className="App">
      {TextArea()}
    </div>
  );
  console.log(posts);
  const postsToRender = posts.map((post, i) => (
      Post(i, post.username, post.title, post.contents)
  ));

  return (
    <div className="App">
      {TextArea()}
      {postsToRender}
    </div>
  );
}

export default App;
